import React from 'react'
import './ViewContact.css';
import NoData from '../../asset/svg/no data.svg'

function ViewContact({ view, contact, template, button }) {

    function formatDate(dateString) {

        const date = new Date(dateString);

        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

        // console.log(formattedDate);
        return formattedDate;
    }

    // List of background colors
    const colors = ['#ff9999', '#99ff99', '#9999ff', '#ffff99', '#ff99ff', '#99ffff', '#ffe4c4', '#ffd700', '#40e0d0'];

    return (
        <div className='main-campaignresult'>
            <div className='result-pop-up'>
                <div className='result-head'>
                    <div>Campaign Name: {view.campaignName}</div>
                    <div>Template Name: {view.templateName}</div>
                    <div>Date: {formatDate(view.createDate)}</div>
                </div>
                <div className='result-body'>
                    <div class="content">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className="card-2">
                                        <div className='details-header'>
                                            <div className='details-header-name'>
                                                {/* <p>contact List</p> */}
                                                <p>Message Preview</p>
                                            </div>
                                            <div className='details-header-btn'>
                                            </div>
                                        </div>
                                        <div className='details-body'>
                                            <div className='message-bg'>
                                                <div className='message'>
                                                    <div className='message-image'>
                                                        <img src={template.url} alt='message image' />
                                                    </div>
                                                    <div className='message-body'>
                                                        <div className='message-text' dangerouslySetInnerHTML={{ __html: template.templateBody }}>

                                                        </div>
                                                        <div className='message-footer'>
                                                            {template.footerText}
                                                        </div>
                                                    </div>
                                                    <div className='message-buttons'>
                                                        {button.length !== 0 && (
                                                            button.map((data, index) => (
                                                                <a href={data.buttonUrl} key={index} target="_blank">
                                                                    <div className='message-button'>
                                                                        <i className="fa-regular fa-arrow-up-right-from-square"></i>
                                                                        <span>{data.buttonText}</span>
                                                                    </div>
                                                                </a>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-8'>
                                    <div className="container-fluid" style={{ marginTop: '20px' }}>
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                                            <div className="col-6" style={{ width: "50%" }}>
                                                <div className="card radius-10 border-start border-0 border-3 border-info">
                                                    <a href="#" className="no-underline">
                                                        <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <p className="mb-0 text-secondary">Contact Count</p>
                                                                    <h4 className="my-1 text-info t-c-2">{view.contactCount}</h4>
                                                                </div>
                                                                <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                                                                    <i class="fa-solid fa-paper-plane-top"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-6" style={{ width: "50%" }}>
                                                <div className="card radius-10 border-start border-0 border-3 border-info">
                                                    <a href="#" className="no-underline">
                                                        <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <p className="mb-0 text-secondary">Estimate Amount</p>
                                                                    <h4 className="my-1 text-info">{view.estimateAmount}</h4>
                                                                </div>
                                                                <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                                                                    <i class="fa-solid fa-message-check"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-2">
                                        <div className='details-header'>
                                            <div className='details-header-name'>
                                                {/* <p>contact List</p> */}
                                                <p>Contacts Details</p>
                                            </div>
                                            <div className='details-header-btn'>
                                            </div>
                                        </div>
                                        <div className='details-body'>
                                            <table className='details-table scroll-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='sn-width'>S.No</th>
                                                        <th className='name-width'>Name</th>
                                                        <th>Mobile No</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="custom-tbody">
                                                    {contact.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className='sn-width'>{index + 1}</td>
                                                            <td className='profile name-width'>
                                                                <div className="profile-img-div"><span className="profile-img" style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}><span>{data.fullname.charAt(0).toUpperCase()}</span></span></div>
                                                                <div className='profile-info'>
                                                                    <p className='profile-name'>{data.fullname}</p>
                                                                    <p className='profile-role'>{data.location}</p>
                                                                </div>
                                                            </td>
                                                            <td>{data.mobile_no}</td>
                                                            <td>{formatDate(data.create_date)}</td>
                                                            <td className='action-td'><i class="fa-solid fa-pen-to-square edit-icon"></i><i class="fa-solid fa-trash delete-icon"></i></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {contact.length == 0 &&
                                                <div className='no-data-div'>
                                                    <img src={NoData} alt='no data' className='no-data' />
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewContact