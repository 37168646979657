import React from 'react'
import './ApprovalRequests.css'
import { useState, useEffect } from 'react';
import formatDate from '../../Functions/date';
import formatTime from '../../Functions/time';
import { useContext } from 'react';
import { PrefixUrlContext } from "../..";
import Campaign from '../Campaign/Campaign';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import CampaignResult from '../Result/CampaignResult';
import ViewContact from '../Campaign/ViewContact';

function ApprovalRequests() {
    const backendURL = useContext(PrefixUrlContext);
    const [campaign, setCampaign] = useState([]);
    const [contact, setContact] = useState([]);
    const [template, setTemplate] = useState();
    const [button, setButton] = useState([]);
    const [campaignResult, setCampaignResult] = useState();
    const [result, setResult] = useState();
    const [view, setView] = useState();
    const [result2, setResult2] = useState();
    //Fetching data onload
    useEffect(() => {
        fetchCampaignDetails();
    }, []);

    const fetchCampaignDetails = async () => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/getcampaignmaster', {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            setCampaign(result)
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    const Approve = async (campaign_id, status) => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/approve', {
                method: 'POST',
                body: JSON.stringify({
                    id: campaign_id,
                    status: status
                }),
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include', // Ensure cookies are sent for session management
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            if (result) {
                if (status == 100) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Approved successfully.',
                    });
                } else if(status == 400) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Rejected successfully.',
                    });
                }
                fetchCampaignDetails();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Success',
                    text: 'Something went wrong. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    }
    let jsonData = [
        [
            "8",
            "testing2",
            "linde_biker_awareness",
            "Gokulraj",
            "2024-03-05 15:46:54.367",
            "1"
        ]
    ]

    const closeResult = () => {
        setResult(false);
    }
    const fetchCampaignResult = async (campaignId) => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/getcampaignresult', {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent for session management
                body: JSON.stringify({
                    id: campaignId
                }),
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setCampaignResult(result)
            setResult(true);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    const closeResult2 = () => {
        setResult2(false);
    }

    const fetchViewDetails = async(campaignId, contactId, templateId) => {
        console.log(templateId);
        console.log("*********");
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/campaignview?campaignId='+campaignId+'&contactId='+contactId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }
    
            const result = await response.json();
            console.log(result);
            setView(result);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }

        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/campaigncontactlist?contactId='+contactId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }
    
            const result = await response.json();
            console.log(result);
            setContact(result);
    
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
        
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebyid?templateId=' + templateId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setTemplate(result);
            if (result.button == 0) {
                try {
                    const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebutton?templateId=' + templateId, {
                        method: 'GET',
                        credentials: 'include', // Ensure cookies are sent for session management
                        headers: {
                            'Content-Type': 'application/json', // Specify content type for clarity
                        },
                        credentials: 'include',
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                    }

                    const result = await response.json();
                    console.log(result);
                    setButton(result);


                } catch (error) {
                    console.error('Error fetching contact details:', error);
                }
            } else {
                console.log("no button");
                setButton([]);
            }

        } catch (error) {
            console.error('Error fetching contact details:', error);
        }

        setResult2(true);
    }

    // List of background colors
    const colors = ['#ff9999', '#99ff99', '#9999ff', '#ffff99', '#ff99ff', '#99ffff', '#ffe4c4', '#ffd700', '#40e0d0'];
    return (
        <div className='main-pending'>
            <div className='content-container'>
                <div className='details-row w3-animate-bottom'>
                    <div className="card-2">
                        <div className='details-header'>
                            <div className='details-header-name'>
                                {/* <p>contact List</p> */}
                                <p>Pending Details</p>
                            </div>
                            <div className='details-header-btn'>
                                <button className='payroll-btn-1'><i class="fa-regular fa-bars-filter"></i> Filter</button>
                                <button className='payroll-btn-1'><i class="fa-regular fa-arrow-right-from-bracket" style={{ transform: 'rotate(270deg)' }}></i> Export</button>
                            </div>
                        </div>
                        <div className='details-body'>
                            <table className='details-table'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Campaign</th>
                                        <th>Template</th>
                                        <th>Created By</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>View</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {campaign.map((data, index) => (
                                        <tr key={index} className='bottom-line'>
                                            <td>{index + 1}</td>
                                            <td className='profile'>
                                                <div className="profile-img-div"><span className="profile-img" style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}><span>{data[1].charAt(0).toUpperCase()}</span></span></div>
                                                <div className='profile-info'>
                                                    <p className='profile-name'>{data[1]}</p>
                                                    <p className='profile-role'><i class="fa-duotone fa-chart-simple"></i> approvel process</p>
                                                </div>
                                            </td>
                                            <td>{data[2]}</td>
                                            <td>{data[3]}</td>
                                            <td>{formatDate(data[4])}</td>
                                            <td>{formatTime(data[4])}</td>
                                            <td className='action-td'>
                                                <button className='button-2' onClick={() => fetchViewDetails(data[0], data[6], data[7])}>View<i class="fa-duotone fa-eye"></i></button>
                                            </td>
                                            <td>
                                                {data[5] == 1 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-green' onClick={() => Approve(data[0], 100)}>Approve<i class="fa-duotone fa-badge-check"></i></button>
                                                        <button className='button-2 button-red' onClick={() => Approve(data[0], 400)}>Reject<i class="fa-duotone fa-octagon-xmark"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 100 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-green'>Approved<i class="fa-duotone fa-badge-check"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 400 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-red'>Reject<i class="fa-duotone fa-octagon-xmark"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 200 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-blue' onClick={() => fetchCampaignResult(data[0])}>Result<i class="fa-duotone fa-chart-simple"></i></button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            {result && 
            <div>
                <div className='bg' onClick={() => closeResult()}></div>
                <CampaignResult campaignResult={campaignResult} setResult={setResult} />
            </div>
            }
            {result2 && 
            <div >
                <div className='bg' onClick={() => closeResult2()}></div>
                
                <ViewContact view={view} contact={contact} template={template} button={button}/>
            </div>
            }
        </div>
    )
}

export default ApprovalRequests