import React from 'react'
import './Campaigns.css'
import { useState, useEffect } from 'react';
import formatDate from '../../Functions/date';
import formatTime from '../../Functions/time';
import { useContext } from 'react';
import { PrefixUrlContext } from "../..";
import Success from '../Alert/Success';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { TokenContext } from '../..';
import ViewContact from './ViewContact';
import CampaignResult from '../Result/CampaignResult';
import SendingMessage from '../Progress/SendingMessage';

function Campaigns() {
    const backendURL = useContext(PrefixUrlContext);
    const [campaigns, setCampaigns] = useState([]);
    const [campaignResult, setCampaignResult] = useState();
    const [result, setResult] = useState();
    const token = useContext(TokenContext);
    const [success, setSuccess] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const [view, setView] = useState();
    const [contact, setContact] = useState([]);
    const [template, setTemplate] = useState();
    const [button, setButton] = useState([]);
    const [result2, setResult2] = useState();
    const [sendProgress, setSendProgress] = useState(false);

    //Fetching data onload
    useEffect(() => {
        fetchCampaigns();
    }, []);

    const fetchCampaigns = async () => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/getcampaignunit', {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setCampaigns(result)
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    const fetchCampaignResult = async (campaignId) => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/getcampaignresult', {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent for session management
                body: JSON.stringify({
                    id: campaignId
                }),
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setCampaignResult(result)
            setResult(true);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    // List of background colors
    const colors = ['#ff9999', '#99ff99', '#9999ff', '#ffff99', '#ff99ff', '#99ffff', '#ffe4c4', '#ffd700', '#40e0d0'];

    const sentMessage = async (campaignId) => {
        Swal.fire({
            title: "Sent Message?",
            text: "Do you want to send a message to this campaign contact?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSendProgress(true);
                try {
                    const response = await fetch(backendURL + '/lscchat/v1.0/senttemplatebycampaign', {
                        method: 'POST',
                        credentials: 'include', // Ensure cookies are sent for session management
                        body: JSON.stringify({
                            id: campaignId
                        }),
                        headers: {
                            'Content-Type': 'application/json', // Specify content type for clarity
                        },
                        credentials: 'include',
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                    }

                    const result = await response.text();
                    console.log(result);
                    fetchCampaigns();
                    setSuccess(true);
                    setSendProgress(false);

                } catch (error) {
                    console.error('Error fetching contact details:', error);
                }
            }
        });

    }

    const closeResult = () => {
        setResult(false);
    }
    const closeResult2 = () => {
        setResult2(false);
    }
    const fetchViewDetails = async (campaignId, contactId, templateId) => {
        // alert(templateId);
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/campaignview?campaignId=' + campaignId + '&contactId=' + contactId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setView(result);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }

        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/campaigncontactlist?contactId=' + contactId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setContact(result);

        } catch (error) {
            console.error('Error fetching contact details:', error);
        }

        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebyid?templateId=' + templateId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setTemplate(result);
            if (result.button == 0) {
                try {
                    const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebutton?templateId=' + templateId, {
                        method: 'GET',
                        credentials: 'include', // Ensure cookies are sent for session management
                        headers: {
                            'Content-Type': 'application/json', // Specify content type for clarity
                        },
                        credentials: 'include',
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                    }

                    const result = await response.json();
                    console.log(result);
                    setButton(result);


                } catch (error) {
                    console.error('Error fetching contact details:', error);
                }
            } else {
                console.log("no button");
                setButton([]);
            }

        } catch (error) {
            console.error('Error fetching contact details:', error);
        }

        setResult2(true);

    }

    return (
        <div className='main-pending'>
            <div className='content-container'>
                <div className='details-row w3-animate-bottom'>
                    <div className="card-2">
                        <div className='details-header'>
                            <div className='details-header-name'>
                                {/* <p>contact List</p> */}
                                <p>Campaigns Details</p>
                            </div>
                            <div className='details-header-btn'>
                                <button className='payroll-btn-1'><i class="fa-regular fa-bars-filter"></i> Filter</button>
                                <button className='payroll-btn-1'><i class="fa-regular fa-arrow-right-from-bracket" style={{ transform: 'rotate(270deg)' }}></i> Export</button>
                            </div>
                        </div>
                        <div className='details-body'>
                            <table className='details-table'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Campaign</th>
                                        <th>Template</th>
                                        <th>Created By</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>View</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {campaigns.map((data, index) => (
                                        <tr key={index} className='bottom-line'>
                                            <td>{index + 1}</td>
                                            <td className='profile'>
                                                <div className="profile-img-div"><span className="profile-img" style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }}><span>{data[1].charAt(0).toUpperCase()}</span></span></div>
                                                <div className='profile-info'>
                                                    <p className='profile-name'>{data[1]}</p>
                                                    <p className='profile-role'><i class="fa-duotone fa-chart-simple"></i> approvel process</p>
                                                </div>
                                            </td>
                                            <td>{data[2]}</td>
                                            <td>{data[3]}</td>
                                            <td>{formatDate(data[4])}</td>
                                            <td>{formatTime(data[4])}</td>
                                            <td className='action-td'>
                                                <button className='button-2' onClick={() => fetchViewDetails(data[0], data[6], data[7])}>View<i class="fa-duotone fa-eye"></i></button>
                                            </td>
                                            <td>
                                                {data[5] == 1 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-yellow'>Pending<i class="fa-duotone fa-clock"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 100 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-green' onClick={() => sentMessage(data[0])}>Send<i class="fa-duotone fa-paper-plane-top"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 400 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-red'>Rejected<i class="fa-duotone fa-octagon-xmark"></i></button>
                                                    </div>
                                                }
                                                {data[5] == 200 &&
                                                    <div className='button-row'>
                                                        <button className='button-2 button-blue' onClick={() => fetchCampaignResult(data[0])}>Result<i class="fa-duotone fa-chart-simple"></i></button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            {sendProgress && <SendingMessage />}
            {success && <Success messageCount={messageCount} title={"Successfully sent"} description={"Messages sent"} button2={"Go to sent list"} button2URL={"/sendlist"} />}
            {result &&
                <div >
                    <div className='bg' onClick={() => closeResult()}></div>

                    <CampaignResult campaignResult={campaignResult} setResult={setResult} />
                </div>
            }
            {result2 &&
                <div>
                    <div className='bg' onClick={() => closeResult2()}></div>

                    <ViewContact view={view} contact={contact} template={template} button={button} />
                </div>
            }
        </div>
    )
}

export default Campaigns