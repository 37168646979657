import React, { useState } from 'react';
import './MessagePreview.css';

function MessagePreview({template, button}) {
    //{template, button}
    // const [template, setTemplate] = useState({
    //     button: "1",
    //     companyId: 2,
    //     dateTime: "2024-03-03T18:30:00.000+00:00",
    //     depId: 2,
    //     flag: 0,
    //     footerText: null,
    //     templateBody: null,
    //     templateId: 5,
    //     templateLanguage: "en",
    //     templateName: "colte_ashok_sir",
    //     templateTypeId: 2,
    //     unitId: 2,
    //     url: "https://lsc-india.org/Whatsapp%20API/images/colte.png"
    // });
    // const[button, setButton] = useState([]);
    return (
        <div className='message-preview'>
            <div className="card-2">
                <div className='details-header'>
                    <div className='details-header-name'>
                        {/* <p>contact List</p> */}
                        <p>Message Preview</p>
                    </div>
                    <div className='details-header-btn'>
                    </div>
                </div>
                <div className='details-body'>
                    <div className='message-bg'>
                        <div className='message'>
                            <div className='message-image'>
                                <img src={template.url} alt='message image' />
                            </div>
                            <div className='message-body'>
                                <div className='message-text' dangerouslySetInnerHTML={{ __html: template.templateBody }}>

                                </div>
                                <div className='message-footer'>
                                    {template.footerText}
                                </div>
                            </div>
                            <div className='message-buttons'>
                                {button.length !== 0 && (
                                    button.map((data, index) => (
                                        <a href={data.buttonUrl} key={index} target="_blank">
                                            <div className='message-button'>
                                                <i className="fa-regular fa-arrow-up-right-from-square"></i>
                                                <span>{data.buttonText}</span>
                                            </div>
                                        </a>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessagePreview