import React, { useEffect, useState, useContext } from 'react'
import './Campaign.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { PrefixUrlContext } from '../..';
import UploadingContact from '../Progress/UploadingContact';
import MessagePreview from './MessagePreview';

function Campaign() {
    const backendURL = useContext(PrefixUrlContext);
    const [contactType, setContactType] = useState(1);
    const [template, setTemplate] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(false);
    const [templatePreview, setTemplatePreview] = useState();
    const [button, setButton] = useState([]);
    const [displayPreview, setDisplayPreview] = useState(false);

    useEffect(() => {
        const getTemplate = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/gettemplate', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }

                const result = await response.json();
                setTemplate(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        };
        getTemplate();
        const getCampaigns = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getcampaignslist', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }

                const result = await response.json();
                setCampaigns(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        };
        getCampaigns();
    }, [])

    const displayType = () => {
        let contacttype = document.getElementById('contact-type').value;
        if (contacttype == "") {
            setContactType(0);
        } else {
            setContactType(contacttype);
        }
        console.log(contactType);
    }

    const checkCampaign = () => {

        let campaignName = document.getElementById('campaign-name');
        let campaignError = document.getElementById('campaign-error');
        if (campaigns.some(data => data.campaign === campaignName.value)) {
            campaignName.classList.add("input-error");
            campaignError.innerHTML = "Campaign name already exists.";
            return;
        } else {
            campaignName.classList.remove("input-error");
            campaignError.innerHTML = "";
        }
    }

    const uploadCampaignByExcel = async (file, campaign, template) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('campaign', campaign);
        formData.append('template', template);
        setUploadProgress(true);
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/createcampaignexcel', {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setUploadProgress(false);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your campaign was created successfully.',
            });
            document.getElementById("campaign-form").reset();
            const data = await response.text();
            console.log('Response from server:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const uploadCampaignByGroup = async (campaign, template, contactGroup) => {
        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/createcampaigngroup', {
                method: 'POST',
                body: JSON.stringify({
                    campaign: campaign,
                    template: template,
                    contactGroupId: contactGroup
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your campaign was created successfully.',
            });
            const data = await response.text();
            console.log('Response from server:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission

        let campaignName = document.getElementById('campaign-name');
        let template = document.getElementById('template');
        let contactTypeInput = document.getElementById('contact-type');
        let campaignError = document.getElementById('campaign-error');
        let templateError = document.getElementById('template-error');
        let contactError = document.getElementById('contact-error');

        if (campaignName.value == "") {
            campaignName.classList.add("input-error");
            campaignError.innerHTML = "Please enter the campaign name.";
            return;
        } else {
            if (campaigns.some(data => data.campaign === campaignName.value)) {
                console.log("call IFFF");
                campaignName.classList.add("input-error");
                campaignError.innerHTML = "Campaign name already exists.";
                return;
            } else {
                campaignName.classList.remove("input-error");
                campaignError.innerHTML = "";
                console.log("call ELSE");
            }
        }

        if (template.value == "") {
            template.classList.add("input-error");
            templateError.innerHTML = "Please select template.";
            return;
        } else {
            template.classList.remove("input-error");
            templateError.innerHTML = "";
        }


        if (contactType == 0) {
            contactTypeInput.classList.add("input-error");
            contactError.innerHTML = "Please select Contact Type.";
            return;
        }
        //Excel upload
        else if (contactType == 1) {

            contactTypeInput.classList.remove("input-error");
            contactError.innerHTML = "";

            const fileInput = document.getElementById('file_id');
            const file = fileInput.files[0];

            let excelError = document.getElementById('excel-error');

            if (file) {
                excelError.innerHTML = "";
                console.log("calling Excel file upload");
                uploadCampaignByExcel(file, campaignName.value, template.value);
            } else {
                excelError.innerHTML = "Please upload Excel.";
            }
        }
        //Contact group
        else if (contactType == 2) {

            contactTypeInput.classList.remove("input-error");
            contactError.innerHTML = "";

            let contactGroup = document.getElementById("contact-group");
            let groupError = document.getElementById("group-error");

            if (contactGroup.value == "") {
                contactGroup.classList.add("input-error");
                groupError.innerHTML = "Please select contact group.";
            } else {
                contactGroup.classList.remove("input-error");
                groupError.innerHTML = "";
                console.log("contact group upload");
                uploadCampaignByGroup(campaignName.value, template.value, contactGroup.value);
            }
        }

    };

    function downloadXLSX() {
        // Import the necessary library
        const XLSX = require('xlsx');

        // Generate XLSX data (replace this with your actual data)
        const data = [
            ["Full Name", "Mobile Number", "Email", "Location"],
            ["Rohit", "919845856948", "rohit@gmail.com", "Chennai"],
            ["Priya", "919845324534", "priya@gmail.com", "Kerala"]
        ];

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Convert the workbook to a binary XLSX file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a blob from the binary data
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link
        const downloadLink = document.createElement('a');

        // Set the href and download attributes
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'addcontacts.xlsx';

        // Append the link to the DOM and trigger the click event
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up by removing the link from the DOM
        document.body.removeChild(downloadLink);
    }

    const displayTemplate = async () => {
        let templateId = document.getElementById("template").value;

        try {
            const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebyid?templateId=' + templateId, {
                method: 'GET',
                credentials: 'include', // Ensure cookies are sent for session management
                headers: {
                    'Content-Type': 'application/json', // Specify content type for clarity
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
            }

            const result = await response.json();
            console.log(result);
            setTemplatePreview(result);
            if (result.button == 0) {
                try {
                    const response = await fetch(backendURL + '/lscchat/v1.0/gettemplatebutton?templateId=' + templateId, {
                        method: 'GET',
                        credentials: 'include', // Ensure cookies are sent for session management
                        headers: {
                            'Content-Type': 'application/json', // Specify content type for clarity
                        },
                        credentials: 'include',
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                    }

                    const result = await response.json();
                    console.log(result);
                    setButton(result);

                } catch (error) {
                    console.error('Error fetching contact details:', error);
                }
            } else {
                console.log("no button");
                setButton([]);
            }

        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
        setDisplayPreview(true);
    }
    return (
        <div className='main-campaign'>
            <div className='container flex-row'>
                <form encType="multipart/form-data" id='campaign-form' className='c-form' onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4 className='card-title'>Create Campaign</h4>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="input-wrapper">
                                <input type="text" id="campaign-name" className='input-1' placeholder='Campaign Name' onChange={checkCampaign} />
                                <span id='campaign-error' className='error'></span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="input-wrapper">
                                <select id="template" className='input-1' placeholder="Template" onChange={displayTemplate}>
                                    <option value="">--Select Template--</option>
                                    {template.map((data, index) => (
                                        <option key={index} value={data.templateId}>{data.templateName}</option>
                                    ))}
                                </select>
                                <span id='template-error' className='error'></span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="input-wrapper">
                                <select id="contact-type" className='input-1' placeholder="Contact Type" onChange={displayType} required>
                                    <option value="0">--Select contact Type--</option>
                                    <option value="1" selected>Excel</option>
                                    <option value="2">Select exist contact group</option>
                                </select>
                                <span id='contact-error' className='error'></span>
                            </div>
                        </div>
                    </div>
                    {contactType == 1 &&
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-wrapper">
                                    <label for="exampleFormControlFile1" class="drop-container" id="dropcontainer">
                                        <span class="drop-title">Drop files here</span>
                                        or
                                        <input type="file" class="form-control-file" id="file_id" name="file" accept=".xls, .xlsx" />
                                    </label>
                                    <span id='excel-error' className='file-error'></span>
                                </div>
                                <div className='excel-formet'><button onClick={downloadXLSX}>Excel Format <i className="fa-solid fa-download"></i></button></div>
                            </div>
                        </div>
                    }
                    {contactType == 2 &&
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-wrapper">
                                    <select id="contact-group" className='input-1' placeholder="Contact Group">
                                        <option value="">--Select contact group--</option>
                                        {campaigns.map((data, index) => (
                                            <option key={index} value={data.contactGroupId}>{data.campaign}</option>
                                        ))}
                                    </select>
                                    <span id='group-error' className='error'></span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="input-wrapper">
                                <button className='button-2'>Create Campaign</button>
                            </div>
                        </div>
                    </div>
                </form>
                {displayPreview &&
                    <div>
                        <MessagePreview template={templatePreview} button={button} />
                    </div>
                }
            </div>
            {uploadProgress && <UploadingContact />}
        </div>
    )
}

export default Campaign