import React from 'react'
import './CampaignResult.css';
import Chart from 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";

function CampaignResult({campaignResult}) {
    function formatDate(dateString) {

        const date = new Date(dateString);

        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

        // console.log(formattedDate);
        return formattedDate;
    }

    const data = {
        labels: ["Sent", "Delivered", "Read"],
        datasets: [
          {
            label: "Message",
            data: [campaignResult.send, campaignResult.delivered, campaignResult.read],
            backgroundColor: [
                "#96c93d", // Coral
                "#17ead9",// Royal Purple
                "#ff8359", // Avocado Green
                "#70A288", // Sage Green
                "#E97451", // Melon
                "#E08E79", // Peach
              ],
              
            borderColor: [
              "rgba(255,99,132,1)",
              "#17ead9",
              "#ff8359",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 0,
          },
        ],
      };

      const data2 = {
        labels: ["Sent", "Not Sent"],
        datasets: [
          {
            label: "Message",
            data: [campaignResult.send, campaignResult.notSend],
            backgroundColor: [
                "#88B04B", // Melon
                "#ff7676", // Peach
                "#FF6F61", // Coral
                "#00aba9",// Royal Purple
                "#88B04B", // Avocado Green
                "#70A288", // Sage Green
                
              ],
              
            borderColor: [
                "rgba(255, 206, 86, 1)",
              "#ff7676",
              "rgba(255,99,132,1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              
            ],
            borderWidth: 0,
          },
        ],
      };
    return (
        <div className='main-campaignresult'>
            <div className='result-pop-up'>
                <div className='result-head'>
                    <div>Campaign Name: {campaignResult.campaignName}</div>
                    <div>Template Name: {campaignResult.templateName}</div>
                    <div>Date: {formatDate(campaignResult.date)}</div>
                </div>
                <div className='result-body'>
                <div class="content">
                        <div className="container-fluid" style={{ marginTop: '20px' }}>
                            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                                <div className="col">
                                    <div className="card radius-10 border-start border-0 border-3 border-info">
                                        <a href="#" className="no-underline">
                                            <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-secondary">Sent</p>
                                                        <h4 className="my-1 text-info t-c-2">{campaignResult.send}</h4>
                                                    </div>
                                                    <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                                                    <i class="fa-solid fa-paper-plane-top"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card radius-10 border-start border-0 border-3 border-info">
                                        <a href="#" className="no-underline">
                                            <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-secondary">Delivered</p>
                                                        <h4 className="my-1 text-info">{campaignResult.delivered}</h4>
                                                    </div>
                                                    <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                                                    <i class="fa-solid fa-message-check"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                
                                <div className="col">
                                    <div className="card radius-10 border-start border-0 border-3 border-info">
                                        <a href="#" className="no-underline">
                                            <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-secondary">Read</p>
                                                        <h4 className="my-1 text-info t-c-3">{campaignResult.read}</h4>
                                                    </div>
                                                    <div className="widgets-icons-2 rounded-circle  bg-gradient-blooker text-white ms-auto">
                                                    <i class="fa-solid fa-badge-check"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card radius-10 border-start border-0 border-3 border-info">
                                        <a href="#" className="no-underline">
                                            <div className="card-body" style={{ padding: '1.25rem', border: 'none', margin: '0' }}>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-secondary">Not Sent</p>
                                                        <h4 className="my-1 text-info t-c-5">{campaignResult.notSend}</h4>
                                                    </div>
                                                    <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                                                    <i class="fa-solid fa-circle-exclamation"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='result-chart'>
                    <div className='left-chart'>
                        <Doughnut data={data} width={100} height={100} />
                    </div>
                    <div className='right-chart'>
                        <Doughnut data={data2} width={100} height={100} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignResult