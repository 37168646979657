import React from 'react'
import './SendingMessage.css'

function UploadingContact() {
  return (
    <div className='sm-bg'>
        <div className='sm-div'>
            <div><i class="fa-duotone fa-cloud-arrow-up"></i></div>
            <div className='sm-icon'><div class="sm-loader"></div> </div>
            <div className='sm-h'>Uploading Contact..</div>
            <div className='sm-p'>Please wait, do not refresh.</div>
        </div>
    </div>
  )
}

export default UploadingContact