import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import './Dashboard.css';
import Logo from '../../asset/lsc_logo.png';
import Userlogo from '../../asset/profile (2).png';
import Rupee from '../../asset/rupee.png';
import Chart from 'chart.js/auto';
import { Link } from 'react-router-dom';
import { Doughnut } from "react-chartjs-2";
import { UserContext } from '../..';
import { PrefixUrlContext } from "../..";

import NoData from "../../asset/svg/no data.svg"

function Dashboard() {
    const chartContainer = useRef(null);
    const { userDetails, setUserDetails } = useContext(UserContext);
    const backendURL = useContext(PrefixUrlContext);
    const [messageCount, setMessageCount] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [sixMonthCost, setSixMonthCost] = useState();
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [select, setSelect] = useState(false);
    
    useEffect(() => {

        if(sixMonthCost != undefined) {
        const ctx = chartContainer.current;

        const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [
                    sixMonthCost[0][0],
                    sixMonthCost[1][0],
                    sixMonthCost[2][0],
                    sixMonthCost[3][0],
                    sixMonthCost[4][0],
                    sixMonthCost[5][0],
                ],
                datasets: [
                    {
                        label: 'Monthly Income',
                        data: [
                            { x: sixMonthCost[0][0], y: sixMonthCost[0][1] },
                            { x: sixMonthCost[1][0], y: sixMonthCost[1][1] },
                            { x: sixMonthCost[2][0], y: sixMonthCost[2][1] },
                            { x: sixMonthCost[3][0], y: sixMonthCost[3][1] },
                            { x: sixMonthCost[4][0], y: sixMonthCost[4][1] },
                            { x: sixMonthCost[5][0], y: sixMonthCost[5][1] },
                        ],
                        backgroundColor: 'black',
                        borderColor: 'coral',
                        borderRadius: 6,
                        cubicInterpolationMode: 'monotone',
                        fill: false,
                        borderSkipped: false,
                    },
                ],
            },
            options: {
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: '',
                        padding: { bottom: 24 },
                        font: {
                            size: 16,
                            weight: 'normal',
                        },
                    },
                    tooltip: {
                        backgroundColor: 'orange',
                        bodyColor: '#272626',
                        yAlign: 'bottom',
                        cornerRadius: 4,
                        titleColor: '#272626',
                        usePointStyle: true,
                        callbacks: {
                            label: function (context) {
                                if (context.dataset.label === 'Monthly Income') {
                                    const label = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'INR',
                                    }).format(context.parsed.y);
                                    return label;
                                } else {
                                    return context.parsed.x;
                                }
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        title: {
                            text: 'Months',
                        },
                    },
                    y: {
                        grid: {
                            color: '#27292D',
                        },
                        title: {
                            display: true,
                            text: '',
                            padding: { bottom: 16 },
                        },
                        ticks: {
                            stepSize: 500,
                            callback: function (value, index, values) {
                                if (value === 0) return value;
                                return new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'INR',
                                }).format(value);
                            },
                        },
                    },
                },
            },
        });
        return () => {
            myChart.destroy();
        };
    }
        
    }, [sixMonthCost]);

    useEffect(() =>{
        console.log("messageCount");
        const updatedData = {
            labels: ["Sent", "Delivered", "Read"],
            datasets: [
                {
                    label: "Message",
                    data: [messageCount.sent, messageCount.delivered, messageCount.read],
                    backgroundColor: [
                        "#96c93d", // Coral
                        "#17ead9", // Royal Purple
                        "#ff8359", // Avocado Green
                    ],
                    borderColor: [
                        "rgba(255,99,132,1)",
                        "#17ead9",
                        "#ff8359",
                    ],
                    borderWidth: 0,
                    pointStyle: 'circle', // Set pointStyle to 'circle'
                },
            ],
        };
    
        const updatedData2 = {
            labels: ["Sent", "Not Sent"],
            datasets: [
                {
                    label: "Message",
                    data: [messageCount.sent, messageCount.notSent],
                    backgroundColor: [
                        "#96c93d", // Coral
                        "#ff8359", // Avocado Green
                    ],
                    borderColor: [
                        "rgba(255,99,132,1)",
                        "#ff8359",
                    ],
                    borderWidth: 0,
                    pointStyle: 'circle', // Set pointStyle to 'circle'
                },
            ],
        };

        setData(updatedData);
        setData2(updatedData2);
    }, [messageCount]);

    useEffect(()=>{
        const dashboardCampaigns = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getdashboardcampaign?getUnit=0', {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
                const result = await response.json();

                setCampaigns(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        };

        dashboardCampaigns();

        const fetchMessageCount = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getmessagecountemployee?getUnit=0', {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
    
                const result = await response.json();
                console.log(result);
                setMessageCount(result)

                setSelect(result.userRole == 3 ? true : false);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        }

        fetchMessageCount();

        const fetchLastSixMonthCost = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getsixmonthcost?getUnit=0', {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
    
                const result = await response.json();
                console.log(result);
                setSixMonthCost(result)
                console.log(result[0][0]);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        }

        fetchLastSixMonthCost();

        const getTeamMembers = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getteammembers', {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
    
                const result = await response.json();
                setTeamMembers(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        }
        getTeamMembers();
    }, [])

    const changeDashboard = () => {
        let dashboardValue = document.getElementById("team-report").value;

        const fetchMessageCount = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getmessagecountemployee?getUnit='+dashboardValue, {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
    
                const result = await response.json();
                console.log(result);
                setMessageCount(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        }

        fetchMessageCount();

        const fetchLastSixMonthCost = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getsixmonthcost?getUnit='+dashboardValue, {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
    
                const result = await response.json();
                console.log(result);
                setSixMonthCost(result)
                console.log(result[0][0]);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        }

        fetchLastSixMonthCost();

        const dashboardCampaigns = async () => {
            try {
                const response = await fetch(backendURL + '/lscchat/v1.0/getdashboardcampaign?getUnit='+dashboardValue, {
                    method: 'GET',
                    credentials: 'include', // Ensure cookies are sent for session management
                    headers: {
                        'Content-Type': 'application/json', // Specify content type for clarity
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`); // Provide specific error details
                }
                const result = await response.json();

                setCampaigns(result)
            } catch (error) {
                console.error('Error fetching contact details:', error);
            }
        };

        dashboardCampaigns();
    }

    return (
        <div className='main-dashboard'>
            <div className='container box-container'>
                <div className='row'>
                    <div className='col-6 h-left'>
                        <div className='c-details'>
                            <div className='c-logo'>
                                <img src={Logo} alt="" />
                            </div>
                            <div className='c-name'>
                                <p>{messageCount.CompanyName}</p>
                            </div>
                        </div>
                        {select && 
                        <div className='team-report-select'>
                            <select name='team-report' className='team-report' id='team-report' onChange={()=>changeDashboard()}>
                                <option value={0}>You</option>
                                <option value={-1}>Your Team</option>
                                {teamMembers.map((data, index) => (
                                    <option value={data.unitId} key={index}>{data.name}</option>
                                ))
                                }
                            </select>
                        </div>
                        }
                    </div>
                    <div className='col-6 h-right'>
                        <div className='u-details'>
                            <div className='u-logo'>
                                <img src={Userlogo} alt='' />
                            </div>
                            <div className='u-name'>
                                <p>{messageCount.fullname}</p>
                            </div>
                        </div>
                        <div className='u-details'>
                            <div className='u-logo'>
                                <img src={Rupee} alt='' />
                            </div>
                            <div className='u-name'>
                                <p>{messageCount.amountSpend}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container box-container">
                <div className="row das-card-div">
                    <div className="das-card">
                        <div className="card bg-c-blue order-card">
                            <div className="card-block">
                                <h6 className="m-b-20">Sent</h6>
                                <h2 className="text-right"><i class="fa-solid fa-paper-plane-top"></i><span>{messageCount.sent}</span></h2>
                                <p className="m-b-0">Percentage<span className="f-right">{messageCount.sentPercentage}%</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="das-card">
                        <div className="card bg-c-green order-card">
                            <div className="card-block">
                                <h6 className="m-b-20">Delivered</h6>
                                <h2 className="text-right"><i class="fa-solid fa-message-check"></i><span>{messageCount.delivered}</span></h2>
                                <p className="m-b-0">Percentage<span className="f-right">{messageCount.deliveredPercentage}%</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="das-card">
                        <div className="card bg-c-yellow order-card">
                            <div className="card-block">
                                <h6 className="m-b-20">Read</h6>
                                <h2 className="text-right"><i class="fa-solid fa-badge-check"></i><span>{messageCount.read}</span></h2>
                                <p className="m-b-0">Percentage<span className="f-right">{messageCount.readPercentage}%</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="das-card">
                        <div className="card bg-c-pink order-card">
                            <div className="card-block">
                                <h6 className="m-b-20">Not Sent</h6>
                                <h2 className="text-right"><i class="fa-solid fa-circle-exclamation"></i><span>{messageCount.notSent}</span></h2>
                                <p className="m-b-0">Percentage<span className="f-right">{messageCount.notSentPercentage}%</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="das-card">
                        <div className="card bg-c-blue2 order-card">
                            <div className="card-block">
                                <h6 className="m-b-20">Reply</h6>
                                <h2 className="text-right"><i class="fa-solid fa-reply-all"></i><span>{messageCount.reply}</span></h2>
                                <p className="m-b-0">Percentage<span className="f-right">{messageCount.replyPercentage}%</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container box-container'>
                <div className='row'>
                    <div className='col-7'>
                        <div className='chart-1'>
                            <div className='chart-header'>
                                <i class="fa-duotone fa-chart-simple"></i>
                                <p>Amount Spent in the Last 6 Months</p>
                            </div>
                            <div className='chart-body'>
                                <canvas ref={chartContainer} id="chart"></canvas>
                            </div>

                        </div>
                    </div>
                    <div className='col-5'>
                        <div className='chart-2'>
                            <div className='chart-header'>
                                <div className='chart-header-left'>
                                    <i class="fa-duotone fa-megaphone"></i>
                                    <p>Campaigns</p>
                                </div>
                                <div className='chart-header-right'>
                                    <Link to={select ? '/approvalrequests' : '/campaigns'}><button className='sell-all-btn'>Sell All</button></Link>
                                </div>
                            </div>
                            <div className='chart-body'>
                                <ul className='cam-ul'>
                                    {campaigns.length == 0 && 
                                        <div className='no-data-div'>
                                            <img src={NoData} alt='no data' className='no-data'/>
                                        </div>
                                    }
                                    {campaigns.length != 0 && campaigns.map((data, index) => (
                                        <li className='cam-list' key={index}>
                                            <div className='cam-das-left'>
                                                <div className='cam-das-profile'>
                                                    <span>{data.campaignName.charAt(0).toUpperCase()}</span>
                                                </div>
                                                <div className='cam-das-names'>
                                                    <div className='cam-das-name'>
                                                        <span>{data.campaignName}</span>
                                                    </div>
                                                    <div className='cam-das-tem'>
                                                        <span>{data.templateName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cam-das-right'>
                                                <div className='cam-das-amt'>
                                                    <span>₹ {data.campaignAmount}</span>
                                                </div>
                                                <div className='cam-das-msg'>
                                                    <i class="fa-light fa-comment-dots"></i>
                                                    <span>{data.sentCount}</span>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    
                                    

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container box-container'>
                <div className='row'>
                    {/* <div className='col-4'>
                        <div className='chart-2'>
                            <div className='chart-header'>
                                <div className='chart-header-left'>
                                    <i class="fa-duotone fa-users-medical"></i>
                                    <p>Team Members</p>
                                </div>
                                <div className='chart-header-right'>
                                    <Link to='/campaigns'><button className='sell-all-btn'>Sell All</button></Link>
                                </div>
                            </div>
                            <div className='chart-body'>
                                <ul className='cam-ul'>
                                    <li className='cam-list'>
                                        <div className='cam-das-left'>
                                            <div className='cam-das-profile'>
                                                <span>V</span>
                                            </div>
                                            <div className='cam-das-names'>
                                                <div className='cam-das-name'>
                                                    <span>Vimal Kumar</span>
                                                </div>
                                                <div className='cam-das-tem'>
                                                    <span>Information Technology</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cam-das-right'>
                                            <div className='cam-das-amt'>
                                                <span>₹ 2,000</span>
                                            </div>
                                            <div className='cam-das-msg'>
                                                <i class="fa-light fa-comment-dots"></i>
                                                <span>1000</span>

                                            </div>
                                        </div>
                                    </li>
                                    <li className='cam-list'>
                                        <div className='cam-das-left'>
                                            <div className='cam-das-profile'>
                                                <span>A</span>
                                            </div>
                                            <div className='cam-das-names'>
                                                <div className='cam-das-name'>
                                                    <span>Anitha</span>
                                                </div>
                                                <div className='cam-das-tem'>
                                                    <span>Information Technology</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cam-das-right'>
                                            <div className='cam-das-amt'>
                                                <span>₹ 2,000</span>
                                            </div>
                                            <div className='cam-das-msg'>
                                                <i class="fa-light fa-comment-dots"></i>
                                                <span>1000</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='cam-list'>
                                        <div className='cam-das-left'>
                                            <div className='cam-das-profile'>
                                                <span>G</span>
                                            </div>
                                            <div className='cam-das-names'>
                                                <div className='cam-das-name'>
                                                    <span>Gokulraj</span>
                                                </div>
                                                <div className='cam-das-tem'>
                                                    <span>Information Technology</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cam-das-right'>
                                            <div className='cam-das-amt'>
                                                <span>₹ 2,000</span>
                                            </div>
                                            <div className='cam-das-msg'>
                                                <i class="fa-light fa-comment-dots"></i>
                                                <span>1000</span>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div> */}

                    <div className='col-4'>
                        {data != null &&
                            <div className='chart-2'>
                                <div className='chart-header'>
                                    <div className='chart-header-left'>
                                        <i class="fa-duotone fa-message-dots"></i>
                                        <p>Messages Chart</p>
                                    </div>
                                </div>
                                <div className='chart-body d-donut'>
                                    <Doughnut data={data} width={100} height={100} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-4'>
                        {data2 != null &&
                            <div className='chart-2'>
                                <div className='chart-header'>
                                    <div className='chart-header-left'>
                                        <i class="fa-duotone fa-message-dots"></i>
                                        <p>Sent Vs Not sent</p>
                                    </div>
                                </div>
                                <div className='chart-body d-donut'>
                                    <Doughnut data={data2} width={100} height={100} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard